import React from "react";
import type {VoidFunctionComponent} from "react";
import s from "./TimeAgo.module.scss";
import {useTimeAgoLogic} from "./TimeAgo.logic";
import {toLocalTime} from "../../../factories/time";

export interface ITimeAgoProps {
  timestamp?: string;
  dayPreviousTotalSec?: number;
}

export const TimeAgo: VoidFunctionComponent<ITimeAgoProps> = ({timestamp, dayPreviousTotalSec}) => {
  const {timeAgo, timeTotal} = useTimeAgoLogic(timestamp, dayPreviousTotalSec);
  return (
    <>
      {toLocalTime(`${timestamp}`)}
      <span className={s.ago}>[ {timeAgo ?? `...`} ]</span>
      {timeTotal && <div className={s.dayTotal}>Day Total: <span className={s.value}>{timeTotal}</span></div>}
    </>
  );
}

