import type {AppState} from "../store";
import type {ScreenStoreType} from "./screen.slice";
import {
  IScreenPresenceData,
  ITeamMemberDailyActivityStats,
  ScreenDisplayType,
  ScreenUuidType
} from "../../services/firebase/database-types";
import {isDefined, isUndefined} from "is-type-util";
import {createSelector} from "@reduxjs/toolkit";
import {getTeamMembersActivityStatsById} from "../team-members/team-members.selectors";
import {getDisplayConfigChargeCodes} from "../display-config/display-config.selectors";


const getScreenStore = (store: AppState): ScreenStoreType =>
  store.screen;

export const getScreenUuid = (store: AppState): ScreenUuidType | undefined =>
  getScreenStore(store).uuid;

export const isScreenSetup = (store: AppState): boolean => {
  const {uuid, doesNeedSetup, ...otherData} = getScreenStore(store);
  return (
    !doesNeedSetup
    && !!Object.keys(otherData).length
  );
}

export const getScreenShouldDisplayUtilizationSummary = (store: AppState): boolean =>
  getScreenStore(store).shouldDisplayUtilizationSummary ?? false;

export const hasScreenUuid = (store: AppState): boolean =>
  isDefined(getScreenUuid(store));
export const getScreenType = (store: AppState): ScreenDisplayType | undefined =>
  getScreenStore(store).type;
export const getScreenTheme = (store: AppState): string | undefined =>
  getScreenStore(store).theme;
export const getScreenTitle = (store: AppState): string =>
  `${getScreenStore(store).title ?? `unknown`}`;

export const getScreenTeamMemberIds = (store: AppState): number[] =>
  getScreenStore(store).teamMembers ?? [];
export const getScreenHasTeamMembers = (store: AppState): boolean =>
  getScreenTeamMemberIds(store).length > 0;

export const getScreenServiceBoardIds = (store: AppState): number[] =>
  getScreenStore(store).serviceBoards ?? [];
export const getScreenHasServiceBoards = (store: AppState): boolean =>
  getScreenServiceBoardIds(store).length > 0;

const getScreenPresenceStore = (store: AppState): IScreenPresenceData =>
  getScreenStore(store).presence ?? {};

export const shouldScreenReload = (store: AppState): boolean =>
  getScreenPresenceStore(store).shouldReload ?? false;


export interface IScreenUtilizationCalculatedSummary {
  total: number;
  overhead: number;
  tickets: number;
  chargeCodes: number;
  personal: Record<number, number>;
  personalNames: Record<number, string>;
}
export const getScreenUtilizationSummary = createSelector(
  [
    getScreenShouldDisplayUtilizationSummary,
    getScreenTeamMemberIds,
    getTeamMembersActivityStatsById,
    getDisplayConfigChargeCodes,
  ],
  (shouldDisplay, teamMemberIds, statsById, chargeCodes): undefined | IScreenUtilizationCalculatedSummary => {
    if (!shouldDisplay || isUndefined(statsById) || isUndefined(chargeCodes)) {
      // shouldn't display, so don't need to calculate
      return undefined;
    }

    let totalValue: number = 0;
    let overheadValue: number = 0;
    let ticketsValue: number = 0;
    let chargeCodesValue: number = 0;
    let personalValue: Record<number, number> = {};
    let personalNames: Record<number, string> = {};


    teamMemberIds.forEach((teamMemberId) => {
      const teamMemberStats: Partial<ITeamMemberDailyActivityStats> = statsById[teamMemberId] ?? {};
      // add ticket time
      totalValue = totalValue + Math.floor((teamMemberStats.tickets ?? 0 / 60) / 60);
      ticketsValue = ticketsValue + Math.floor((teamMemberStats.tickets ?? 0 / 60) / 60);
      // process over all charge codes
      Object.entries(teamMemberStats.charge_codes ?? {})
        .forEach(([chargeCodeId, chargeCodeValue]) => {
          const chargeCodeIdNumber = parseInt(`${chargeCodeId}`);
          const valueToAdd = Math.floor(chargeCodeValue/ 60);

          if (chargeCodeIdNumber === 14) {
            // is overhead
            overheadValue = overheadValue + valueToAdd;

          } else if (chargeCodes?.[chargeCodeIdNumber]?.isPersonalTime) {
            if (isUndefined(personalValue[chargeCodeIdNumber])) {
              personalValue[chargeCodeIdNumber] = 0;
              personalNames[chargeCodeIdNumber] = chargeCodes?.[chargeCodeIdNumber]?.name ?? `Unknown ${chargeCodeIdNumber}`;
            }
            // make sure set
            personalValue[chargeCodeIdNumber] = personalValue[chargeCodeIdNumber] + valueToAdd;
          } else {
            chargeCodesValue = chargeCodesValue  + valueToAdd;
          }
          totalValue = totalValue + valueToAdd;
        });
    });

    return <IScreenUtilizationCalculatedSummary>{
      total: totalValue,
      overhead: overheadValue,
      tickets: ticketsValue,
      chargeCodes: chargeCodesValue,
      personal: {
        ...personalValue,
      },
      personalNames,
    };
  }
)
