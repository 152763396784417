import {useEffect, useState} from "react";
import moment from "moment-timezone";
import {toLocalTimezone} from "../../../factories/time";
import {isUndefined} from "is-type-util";

export interface ITimeAgoLogic {
  timeAgo?: string;
  timeTotal?: string;
}

const defaultUpdateMs = 5000;
export const useTimeAgoLogic = (timestamp?: string, dayPreviousTotalSec?: number): ITimeAgoLogic => {
  const [timeState, setTimeState] = useState<moment.Moment | undefined>();
  const [timeDayTotalState, setTimeDayTotalState] = useState<moment.Moment | undefined>();
  const [timeAgo, setTimeAgo] = useState<string | undefined>();
  const [timeTotal, setTimeTotal] = useState<string | undefined>();

  useEffect(() => {
    if (isUndefined(timestamp)) {
      setTimeState(undefined);
    } else {
      setTimeState(toLocalTimezone(timestamp));
    }
  }, [timestamp]);

  useEffect(() => {

    if (isUndefined(dayPreviousTotalSec) || !dayPreviousTotalSec) {
      setTimeDayTotalState(undefined);
      return;
    }

    let timeDayTotal = undefined;
    if (isUndefined(timeState)) {
      // don't have a previous timestamp, so we don't have
      timeDayTotal = toLocalTimezone('now');
    } else {
      timeDayTotal = timeState?.clone();
    }

    if (!isUndefined(dayPreviousTotalSec)) {
      timeDayTotal.subtract(dayPreviousTotalSec, 'seconds');
    }

    setTimeDayTotalState(timeDayTotal);

  }, [timeState, dayPreviousTotalSec])

  useEffect(() => {
    if (isUndefined(timeState)) {
      setTimeAgo(undefined);
      // return to exit
      return;
    }
    // update time ago
    setTimeAgo(timeState?.fromNow())
    const updateTimeInterval = setInterval(
      () => setTimeAgo(timeState?.fromNow(true))
      , defaultUpdateMs
    );

    return () => clearInterval(updateTimeInterval);
  }, [timeState]);



  useEffect(() => {
    if (isUndefined(timeDayTotalState)) {
      setTimeAgo(undefined);
      // return to exit
      return;
    }

    const calculatedDayTotal = (): string => {
      const duration = moment.duration(moment().diff(timeDayTotalState));
      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.asMinutes() % 60);

      return `${hours}:${(minutes < 10 ? `0${minutes}` : minutes)}`;
    }
    // update time ago
    setTimeTotal(calculatedDayTotal)
    const updateTimeInterval = setInterval(
        () => setTimeTotal(calculatedDayTotal)
        , defaultUpdateMs
    );

    return () => clearInterval(updateTimeInterval);
  }, [timeDayTotalState]);

  return {
    timeAgo,
    timeTotal,
  };
}
