import React from "react";
import type {VoidFunctionComponent} from "react";
import {useTeamMemberPreviousActivityTimeLogic} from "./TeamMemberPreviousActivityTime.logic";
import {TimeAgo} from "../../../time/time-ago/TimeAgo";

export interface ITeamMemberPreviousActivityTimeProps {
  teamMemberId: number;
}

export const TeamMemberPreviousActivityTime: VoidFunctionComponent<ITeamMemberPreviousActivityTimeProps> = ({teamMemberId}) => {
  const {timestamp, dayPreviousTotalSec} = useTeamMemberPreviousActivityTimeLogic(teamMemberId);
  return <TimeAgo timestamp={timestamp} dayPreviousTotalSec={dayPreviousTotalSec} />;
}

