import {useAppSelector} from "../../redux/hooks";
import {
  getScreenShouldDisplayUtilizationSummary,
  getScreenUtilizationSummary, type IScreenUtilizationCalculatedSummary
} from "../../redux/screen/screen.selectors";


export interface IUtilizationSummaryLogic {
  shouldDisplay: boolean;
  summary?: IScreenUtilizationCalculatedSummary
}

export const useUtilizationSummaryLogic = (): IUtilizationSummaryLogic => {
  const shouldDisplay = useAppSelector(getScreenShouldDisplayUtilizationSummary)

  const summary = useAppSelector(getScreenUtilizationSummary);

  return {
    shouldDisplay,
    summary,
  };
}
