import {useAppSelectorWithParams} from "../../../../redux/hooks";
import {
  getDisplayStyleByTypeAndId
} from "../../../../redux/display-config/display-config.selectors";
import {DisplayConfigStyle} from "../../../../services/firebase/database-types";
import {calculatePercentage} from "../../../../util/number-util";

export interface ITeamMemberDayTimelineEntryLogic {
  style?: DisplayConfigStyle & {flexBasis: string;};
}

export const useTeamMemberDayTimelineEntryLogic = (id: number, type: "ChargeCode" | "ServiceTicket", duration: number, totalDuration: number,): ITeamMemberDayTimelineEntryLogic => {
  const style = useAppSelectorWithParams(getDisplayStyleByTypeAndId, type, id);
  const displayWidthPercent = calculatePercentage(duration, totalDuration);
  return {
    style: {
      ...style,
      flexBasis: `${displayWidthPercent}%`,
    }
  };
}
