import React, {FunctionComponent} from "react";
import {useUtilizationSummaryLogic} from "./UtilizationSummary.logic";
import s from './UtilizationSummary.module.scss'
import Paper from '@mui/material/Paper';
import {UtilizationSummaryCard} from "../../components/utilization/utilization-summary-card/UtilizationSummaryCard";





export const UtilizationSummary: FunctionComponent = ({}) => {
  const {shouldDisplay, summary} = useUtilizationSummaryLogic();
  if (!shouldDisplay) {
    // shouldn't be displayed on screen
    return null;
  }
  return (
    <div className={s.container}>
      <Paper elevation={2} className={s.paper} >
        <div className={s.sectionTitle}>Utilization Summary</div>
        <div className={s.items}>
          <UtilizationSummaryCard name={`Overhead`} duration={summary?.overhead} total={summary?.total} />
          <UtilizationSummaryCard name={`Tickets`} duration={summary?.tickets} total={summary?.total} />
          <UtilizationSummaryCard name={`ChargeCodes`} duration={summary?.chargeCodes} total={summary?.total} />
          {Object.entries(summary?.personal ?? {})
            .map(([key, value]) => <UtilizationSummaryCard key={`summary-personal-${key}`} name={summary?.personalNames[parseInt(`${key}`)] ?? `Unknown[${key}]`} duration={value} total={summary?.total} />)}
        </div>
      </Paper>
    </div>
  );
}
