
export const arrayMax = (inputArray: number[]): number =>
  Math.max(...inputArray);

export const arrayMin = (inputArray: number[]): number =>
  Math.min(...inputArray);

export const arraySum = (inputArray: number[]): number =>
  inputArray.reduce((partialSum, currentValue) => partialSum + currentValue, 0);

