import React from "react";
import type {FunctionComponent} from "react";
import s from "./UtilizationSummaryCard.module.scss";
import {Card, CardContent} from "@mui/material";
import CardHeader from '@mui/material/CardHeader';
import {isUndefined} from "is-type-util";
import {calculatePercentage} from "../../../util/number-util";

const shouldDisplayDays: boolean = false;

const minToReadableFormat = (totalMinutes?: number): string | undefined => {
  if (isUndefined(totalMinutes) || !totalMinutes) {
    // no value in making readable
    return undefined;
  }
  let days: number, hours: number;
  if (shouldDisplayDays){

    days = Math.floor((totalMinutes / 60) / 24);
    hours = Math.floor(totalMinutes / 60) % 24;
  } else {
    days = 0;
    hours = Math.floor(totalMinutes / 60);
  }
  const minutes = totalMinutes % 60;
  const outputChunks: string[] = [];
  if (days > 0) {
    outputChunks.push(`${days}d`);
  }
  if (hours > 0) {
    outputChunks.push(`${hours}h`);
  }
  if (minutes > 0) {
    outputChunks.push(`${minutes}m`);
  }
  return outputChunks.join(` `);
}

export interface IUtilizationSummaryCardProps {
  name: string;
  duration?: number;
  total?: number;
}

export const UtilizationSummaryCard: FunctionComponent<IUtilizationSummaryCardProps> = ({name, duration, total}) => {
  const percentage = calculatePercentage(duration, total);
  return (
    <Card variant={`elevation`} className={s.item} raised={true} sx={{ m:undefined}}>
      <CardHeader title={name} disableTypography={true} className={s.title} sx={{borderBottom: 1}}/>
      <CardContent className={s.content} sx={{px: 0, pt: 0}}>
        <div className={s.percent}>
          {!!percentage ? `${percentage.toFixed(1)} %` : ` `}
        </div>
        <div className={s.value}>
          {minToReadableFormat(duration)}
        </div>
      </CardContent>
    </Card>
  );
}
