import {ITeamMemberDayActivityItem} from "../../../../services/firebase/database-types";
import {useAppSelectorWithParam} from "../../../../redux/hooks";
import {getDailyActivityTimelineByTeamMemberId} from "../../../../redux/team-members/team-members.selectors";
import {arraySum} from "../../../../util/array-math";

export interface ITeamMemberDayTimelineLogic {
  shouldDisplay: boolean;
  timeline: ITeamMemberDayActivityItem[]
  totalDuration: number;
}

export const useTeamMemberDayTimelineLogic = (teamMemberId: number): ITeamMemberDayTimelineLogic => {
  const timeline: ITeamMemberDayActivityItem[] = useAppSelectorWithParam(getDailyActivityTimelineByTeamMemberId, teamMemberId);
  const totalDuration = arraySum(
    timeline.map(({duration}) => duration)
  );

  const shouldDisplay = (timeline.length > 0);
  return {
    shouldDisplay,
    timeline,
    totalDuration,
  };
}
